import {
  CART_ITEM_SELECTION_TYPE,
  SFL_ITEM_SELECTION_TYPE,
  getCartItemIds, getSavedForLaterIds, getSelectedIdsByType, getInitialCartSelectedIds,
  getInitialSflSelectedIds, getInitialCartItemIds, getInitialSflIds,
} from './helper'
import * as mutations from './mutation-types'
import { areListsEqual, isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { mapDistributors, mapSavedForLater } from '~/helpers/cart'
import { API as api } from '~/ZenOne-StoryBook/config/api'
import { RESPONSE_STATUS_NO_CONTENT } from '~/ZenOne-StoryBook/helpers/response'
import { ERROR } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  addCartItemIds({ commit, getters }, { ids = [] }) {
    if (!isArrayWithLength(ids)) return false
    const cartItemIds = [...getInitialCartItemIds(), ...ids]
    const cartItemIdsSet = new Set(cartItemIds)
    commit(mutations.SET_CART_ITEM_IDS, { ids: [...cartItemIdsSet] })
  },
  removeCartItemIds({ commit, dispatch, getters }, { ids = [] }) {
    const cartItemIdsCurrent = getInitialCartItemIds()
    const itemsToRemoveSet = new Set(ids)
    commit(mutations.SET_CART_ITEM_IDS, {
      ids: cartItemIdsCurrent.filter((item) => !itemsToRemoveSet.has(item)),
    })
  },
  addSavedForLaterIds({ commit, getters }, { ids = [] }) {
    if (!isArrayWithLength(ids)) return false
    const cartItemIds = [...getInitialSflIds(), ...ids]
    const cartItemIdsSet = new Set(cartItemIds)
    commit(mutations.SET_SFL_IDS, { ids: [...cartItemIdsSet] })
  },
  removeSavedForLaterIds({ commit, dispatch, getters }, { ids = [] }) {
    const cartItemIdsCurrent = getInitialSflIds()
    const itemsToRemoveSet = new Set(ids)
    commit(mutations.SET_SFL_IDS, {
      ids: cartItemIdsCurrent.filter((item) => !itemsToRemoveSet.has(item)),
    })
  },
  setIsCheckout({ commit, dispatch }, payload) {
    commit(mutations.CLEAR_DISTRIBUTORS)
    commit(mutations.CLEAR_SAVED_FOR_LATER_LIST)
    commit(mutations.SET_IS_CHECKOUT, payload)
  },
  async setCheckoutModeOn({ commit, dispatch }) {
    dispatch('setIsCheckout', true)
    await dispatch('setCartData')
  },
  async setCartModeOn({ commit, dispatch }) {
    dispatch('setIsCheckout', false)
    await dispatch('setCartData')
  },
  async fetchCart({ getters, rootGetters }) {
    const ids = !getters.isAllCartItemsSelected
      ? [...getters.cartSelectedIds]
      : []
    const cartDistributors = await this.$axios.post(
      `${api.PRACTICE_LOCATIONS}/${rootGetters['user/practiceLocationId']}/${api.CART}`,
      { ids }
    )
    return cartDistributors?.data
  },
  async fetchCheckout({ getters, rootGetters }) {
    const ids = !getters.isAllCartItemsSelected
      ? [...getters.cartSelectedIds]
      : []
    const checkoutDistributors = await this.$axios.post(
      `${api.PRACTICE_LOCATIONS}/${rootGetters['user/practiceLocationId']}/${api.CHECKOUT}`,
      { ids }
    )
    return checkoutDistributors?.data
  },
  async fetchSavedForLaterItems({ rootGetters, dispatch }) {
    try {
      const include = `${api.PRODUCT_DISTRIBUTOR}.${api.DISTRIBUTOR},${api.PRODUCT_DISTRIBUTOR}.${api.PRODUCT},${api.PRODUCT_DISTRIBUTOR}.${api.PRODUCT}.${api.PRODUCT_MODEL},${api.PRODUCT_DISTRIBUTOR}.${api.PRODUCT}.${api.IMAGES},${api.PRODUCT_DISTRIBUTOR}.${api.PRODUCT}.${api.IMAGES}.${api.THUMB}`
      const params = {
        include,
        'filter[practiceLocationId]': rootGetters['user/practiceLocationId'],
        'filter[productDistributorStatus]': 'active,backordered',
        'filter[savedForLater]': true,
        sort: '-updatedAt',
        full: true,
      }
      const { data } = await dispatch(
        'jvZen/get',
        [`${api.CART_ITEMS}`, { params }],
        { root: true }
      )
      return data
    } catch {
      console.error('error')
    }
  },
  async setCartData({ commit, dispatch }) {
    commit('ui/SET_SHOW_FULL_SCREEN_LOADER', true, { root: true })
    await Promise.all([
      dispatch('setDistributors'),
      dispatch('setSaveForLater'),
    ])
    commit('ui/SET_SHOW_FULL_SCREEN_LOADER', false, { root: true })
  },
  async setDistributors({ commit, getters, dispatch }) {
    const isCheckout = getters.isCheckout
    dispatch('refreshSelectedIds')
    const fetchMethod = !isCheckout ? 'fetchCart' : 'fetchCheckout'
    const {
      distributors = [],
      cartItemsCount = 0,
      cartItemsTotalCount = 0, // all cart items quantities sum
      savingCosts = 0,
      savingSubtotal = 0,
      withoutSavings = {},
      isHavingActiveSavings = false,
    } = mapDistributors(
      await dispatch(fetchMethod),
      getters.cartSelectedIds,
      getters.isAllCartItemsSelected
    )
    commit(mutations.SET_DISTRIBUTORS, distributors)
    const ids = getCartItemIds(distributors)
    commit(mutations.SET_CART_ITEM_IDS, { ids })

    if (getters.isAllCartItemsSelected) {
      commit(mutations.SET_CART_SELECTED_IDS, { ids })
    } else if (areListsEqual(ids, getters.cartSelectedIds)) {
      commit(mutations.SET_ALL_CART_ITEMS_SELECTED, true)
    } else {
      // if cartItem got deleted in another browser, but still selected in this one - remove it
      commit(mutations.SET_CART_SELECTED_IDS, { ids: getters.cartSelectedIds.filter((item) => ids.includes(item)) })
    }

    commit(mutations.SET_CART_ITEMS_TOTAL_COUNT, cartItemsTotalCount)
    commit(mutations.SET_CART_ITEMS_COUNT, cartItemsCount)
    commit(mutations.SET_SAVING_COSTS, savingCosts)
    commit(mutations.SET_SAVING_SUBTOTAL, savingSubtotal)
    commit(mutations.SET_IS_HAVING_ACTIVE_SAVINGS, isHavingActiveSavings)
    commit(mutations.SET_WITHOUT_SAVINGS, withoutSavings)
  },
  async setSaveForLater({ commit, dispatch, getters }) {
    let list = (await dispatch('fetchSavedForLaterItems')) ?? []
    if (isArrayWithLength(list))
      list =
        mapSavedForLater(list, getters.savedForLaterSelectedIds)
          ?.savedForLaterList ?? []
    commit(mutations.SET_SAVED_FOR_LATER_LIST, list)
    const ids = getSavedForLaterIds(list)
    commit(mutations.SET_SFL_IDS, { ids })
  },
  addItemsToSelected({ commit, getters }, { type = '', ids = [] }) {
    if (type === '' || !isArrayWithLength(ids)) return false
    if (type === CART_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_CART_SELECTED_IDS, {
        ids: [...getInitialCartSelectedIds(), ...ids],
      })
    } else if (type === SFL_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_SAVED_FOR_LATER_SELECTED_IDS, {
        ids: [...getInitialSflSelectedIds(), ...ids],
      })
    }
    return false
  },
  removeItemsFromSelected({ commit, getters }, { type = '', ids = [] }) {
    if (type === '' || !isArrayWithLength(ids)) return false
    if (type === CART_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_CART_SELECTED_IDS, {
        ids: getInitialCartSelectedIds().filter((item) => !ids.includes(item)),
      })
    } else if (type === SFL_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_SAVED_FOR_LATER_SELECTED_IDS, {
        ids: getInitialSflSelectedIds().filter((item) => !ids.includes(item)),
      })
    }
    return false
  },
  async selectAllHandler({ commit, getters, dispatch }, { type = '' }) {
    if (!type) return false
    if (type === CART_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_SELECT_ALL_CART_ITEMS_TRIGGER)
      commit(mutations.SET_CART_SELECTED_IDS, { ids: getInitialCartItemIds() })
      commit(mutations.SET_ALL_CART_ITEMS_SELECTED, true)
      await dispatch('setDistributors')
    } else if (type === SFL_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_SELECT_ALL_SFL_TRIGGER)
      commit(mutations.SET_SAVED_FOR_LATER_SELECTED_IDS, { ids: getInitialSflIds() })
    }
    return false
  },
  deselectAllHandler({ commit, dispatch }, { type = '' }) {
    if (!type) return false
    if (type === CART_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_DESELECT_ALL_CART_ITEMS_TRIGGER)
      commit(mutations.SET_CART_SELECTED_IDS, { ids: [] })
      commit(mutations.SET_ALL_CART_ITEMS_SELECTED, false)
    } else if (type === SFL_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_DESELECT_ALL_SFL_TRIGGER)
      commit(mutations.SET_SAVED_FOR_LATER_SELECTED_IDS, { ids: [], })
    }
    return false
  },
  async checkboxClickHandler(
    { commit, getters, dispatch },
    { type = '', id = null }
  ) {
    let selectedUpdated = []
    const selectedIds = getSelectedIdsByType(type, getters)
    if (selectedIds.includes(id)) { // uncheck
      selectedUpdated = selectedIds.filter((item) => item !== id)
      commit(mutations.SET_ALL_CART_ITEMS_SELECTED, false)
    } else {
      selectedUpdated = [...selectedIds, id]
    }
    if (type === CART_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_CART_SELECTED_IDS, { ids: selectedUpdated })
      await dispatch('setDistributors')
    } else if (type === SFL_ITEM_SELECTION_TYPE) {
      commit(mutations.SET_SAVED_FOR_LATER_SELECTED_IDS, { ids: selectedUpdated })
    }
    return false
  },
  async saveForLater({ commit, dispatch }, { ids = [] }) {
    try {
      const res = await this.$axios.post(`/cart/save_for_later`, {
        ids,
      })
      if (res?.status !== RESPONSE_STATUS_NO_CONTENT) {
        throw new Error('Response error')
      }
      dispatch('removeCartItemIds', { ids })
      dispatch('removeItemsFromSelected', {
        type: CART_ITEM_SELECTION_TYPE,
        ids,
      })
      dispatch('addSavedForLaterIds', { ids })
      dispatch('addItemsToSelected', {
        type: SFL_ITEM_SELECTION_TYPE,
        ids,
      })
      await dispatch('setCartData')
    } catch (e) {
      const error = e?.response?.data?.errors?.[0]?.detail ?? e
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: `Cannot save for later: ${error}`,
          show: true,
        },
        { root: true }
      )
      console.error(error)
    }
  },
  async removeItems(
    { commit, dispatch },
    { ids = [], withoutUpdateRequest = false }
  ) {
    try {
      const res = await this.$axios.post(`/cart/remove_items`, {
        ids,
      })
      if (res?.status !== RESPONSE_STATUS_NO_CONTENT) {
        throw new Error('Response error')
      }
      dispatch('removeCartItemIds', { ids })
      dispatch('removeItemsFromSelected', {
        type: CART_ITEM_SELECTION_TYPE,
        ids,
      })
      dispatch('removeSavedForLaterIds', { ids })
      dispatch('removeItemsFromSelected', {
        type: SFL_ITEM_SELECTION_TYPE,
        ids,
      })
      if (withoutUpdateRequest) return false
      await dispatch('setCartData')
    } catch (e) {
      const error = e?.response?.data?.errors?.[0]?.detail ?? e
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: `Cannot remove items: ${error}`,
          show: true,
        },
        { root: true }
      )
      console.error(error)
    }
  },
  async restoreFromLater({ commit, dispatch }, { ids = [] }) {
    try {
      const res = await this.$axios.post(`/cart/restore_from_later`, {
        ids,
      })
      if (res?.status !== RESPONSE_STATUS_NO_CONTENT) {
        throw new Error('Response error')
      }
      dispatch('addCartItemIds', { ids })
      dispatch('addItemsToSelected', {
        type: CART_ITEM_SELECTION_TYPE,
        ids,
      })
      dispatch('removeSavedForLaterIds', { ids })
      dispatch('removeItemsFromSelected', {
        type: SFL_ITEM_SELECTION_TYPE,
        ids,
      })
      await dispatch('setCartData')
    } catch (e) {
      const error = e?.response?.data?.errors?.[0]?.detail ?? e
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: `Cannot restore from Save For Later: ${error}`,
          show: true,
        },
        { root: true }
      )
      console.error(error)
    }
  },
  setSearchTerm({ commit, dispatch }, payload = '') {
    commit(mutations.SET_SEARCH_TERM, payload)
  },
  clearSearchTerm({ commit }) {
    commit(mutations.SET_SEARCH_TERM, '')
  },
  refreshSelectedIds({ commit }) {
    commit(mutations.SET_CART_SELECTED_IDS, {
      ids: getInitialCartSelectedIds(),
    })
    commit(mutations.SET_SAVED_FOR_LATER_SELECTED_IDS, {
      ids: getInitialSflSelectedIds(),
    })
  }
}
