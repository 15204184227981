
import { mapGetters } from 'vuex'
import mixShrink from '~/ZenOne-StoryBook/mixins/shrink.js'
export default {
  components: {},
  mixins: [mixShrink],
  computed: {
    ...mapGetters('user', ['name']),
  },
}
