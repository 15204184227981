
import _ from 'lodash'
import { directive as onClickaway } from 'vue-clickaway'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import iconSearch from '~/components/img/icon-search.svg'
import { capitalizeFirstLetter } from '~/helpers/format'
import { generateRandomString } from '~/ZenOne-StoryBook/helpers/random'
import { ROOT_ROUTE_NAME } from '~/helpers/route'
import route from '~/mixins/route'
import { DEFAULT_POSITION } from '~/store/modules/search/helper'
import {
  getLocalStorage,
  getLocalStorageProp,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { CATALOG_ALIAS } from '~/components/Catalog/helper'

export default {
  name: 'SearchBarCatalog',
  directives: {
    onClickaway,
  },
  mixins: [route],
  props: {
    showDropdown: {
      type: Boolean,
      default: false,
    },
    inputFocusTrigger: {
      type: Boolean,
      default: false,
    },
    showHistory: {
      type: Boolean,
      default: false,
    },
    showSuggestions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconSearch,
      capitalizeFirstLetter,
      generateRandomString,
    }
  },
  computed: {
    ...mapGetters({
      searchTerm: 'search/searchTerm',
      history: 'search/history',
      joinedSuggestionList: 'search/joinedSuggestionList',
      dropdownPosition: 'search/dropdownPosition',
      clickedOnDeleteHistoryItem: 'search/clickedOnDeleteHistoryItem',
      makeSearchRequestTrigger: 'search/makeSearchRequestTrigger',
    }),
    model: {
      get() {
        return this.searchTerm
      },
      set(value = '') {
        this.setSearchTerm(value)
      },
    },
    placeholder() {
      return this.catalogFiltersInStore?.fromOrderHistory === true &&
        this.isCatalogRoute
        ? 'Search in Order History'
        : 'Search in Catalog'
    },
    showClearBtn() {
      return this.searchTerm !== ''
    },
    suggestionsList() {
      if (this.showHistory) {
        return this.history
      } else if (this.showSuggestions) {
        return this.joinedSuggestionList
      }

      return []
    },
  },
  watch: {
    inputFocusTrigger: {
      handler() {
        this.$refs.searchInput.focus()
      },
    },
    makeSearchRequestTrigger: {
      async handler() {
        await this.makeSearchRequest()
      },
    },
    searchStringInStore: {
      handler(value = '') {
        if (this.searchTerm === value) return false
        this.setSearchTerm(value)
      },
    },
  },
  mounted() {
    const { searchStringInStore = '' } = this
    const searchParamFromUrl = this.$route?.query?.search ?? ''
    if (searchParamFromUrl) {
      this.setSearchTerm(searchParamFromUrl)
    } else if (searchStringInStore) {
      this.setSearchTerm(searchStringInStore)
    }
  },
  methods: {
    ...mapMutations('search', {
      setSearchTerm: 'SET_SEARCH_TERM',
      resetDropdownPosition: 'RESET_DROPDOWN_POSITION',
      setDropdownPosition: 'SET_DROPDOWN_POSITION',
    }),
    ...mapMutations('catalog', {
      setCatalogSearchTerm: 'SET_SEARCH',
      updateFilters: 'UPDATE_FILTERS',
    }),
    ...mapActions({
      FetchSuggestions: 'search/FetchSuggestions',
      OnSelectHistoryItem: 'search/OnSelectHistoryItem',
      OnSelectDropdownCategory: 'search/OnSelectDropdownCategory',
      OnSelectDropdownAttribute: 'search/OnSelectDropdownAttribute',
    }),
    onClickaway() {
      this.hideDropdown()
    },
    clickHandler() {
      if (!this.showDropdown) {
        this.$emit('on-set-show-dropdown', true)
      }
    },
    inputChangeHandler() {
      this.resetDropdownPosition()
      if (!this.showDropdown) {
        this.$emit('on-set-show-dropdown', true)
      }
      this.onSearchSuggest()
    },
    async focusHandler(showDropdown = true) {
      this.resetDropdownPosition()
      await this.FetchSuggestions(this.searchTerm)
      if (showDropdown && !this.showDropdown) {
        this.$emit('on-set-show-dropdown', true)
      }
    },
    blurHandler() {
      setTimeout(() => {
        this.hideDropdown()
      }, 100)
    },
    hideDropdown() {
      if (this.clickedOnDeleteHistoryItem) return false
      this.$emit('on-set-show-dropdown', false)
    },
    dragEndHandler() {
      this.hideDropdown()
      this.focusHandler(false)
    },
    clearSearchInput() {
      this.setSearchTerm('')
      this.updateFilters({
        searchable: '',
      })
      const data = getLocalStorage(CATALOG_ALIAS) ?? {}
      const filters = getLocalStorageProp(CATALOG_ALIAS, 'filters') ?? {}
      filters.searchable = ''
      data.filters = filters
      setLocalStorage({
        name: CATALOG_ALIAS,
        data,
      })
      this.$emit('on-set-show-dropdown', true)
      this.resetDropdownPosition()
      this.$refs.searchInput.focus()
    },
    escapeKeyUpHandler() {
      this.$refs.searchInput.blur()
    },
    clearBtnClickHandler(e) {
      e.preventDefault()
      this.clearSearchInput()
    },
    async makeSearchRequest() {
      this.blurHandler()
      const { searchTerm = '' } = this
      if (!searchTerm) return false

      if (this.$route.name !== ROOT_ROUTE_NAME) {
        await this.$router.push({
          name: ROOT_ROUTE_NAME,
          query: this.$route.query,
        })
      }
      this.setCatalogSearchTerm(this.searchTerm)
    },
    async onKeyEnter() {
      const { dropdownPosition = DEFAULT_POSITION } = this
      if (dropdownPosition === DEFAULT_POSITION) {
        await this.makeSearchRequest()
        return false
      }
      this.onSuggestSelect(this.suggestionsList[dropdownPosition])
      this.blurHandler()
    },
    onSuggestSelect(suggest = {}) {
      if (this.showHistory) {
        this.OnSelectHistoryItem({
          searchQuery: suggest?.attributes?.search_query ?? '',
        })
      } else if (this.showSuggestions) {
        if (suggest?.category_id) {
          this.OnSelectDropdownCategory({ type: 'genericName', suggest })
        } else if (suggest?.modifier_value_id) {
          this.OnSelectDropdownAttribute({
            suggest,
          })
        }
      }
    },
    onDownArrowKey() {
      const { dropdownPosition = DEFAULT_POSITION } = this
      const arrayLength = this.suggestionsList?.length - 1
      if (dropdownPosition >= arrayLength) {
        this.resetDropdownPosition()
      } else {
        this.setDropdownPosition(dropdownPosition + 1)
      }
    },
    onUpArrowKey() {
      const { dropdownPosition = DEFAULT_POSITION } = this
      const arrayLength = this.suggestionsList?.length - 1
      if (dropdownPosition === DEFAULT_POSITION) {
        this.setDropdownPosition(arrayLength)
      } else {
        this.setDropdownPosition(dropdownPosition - 1)
      }
    },
    onSearchSuggest: _.debounce(function () {
      if (this.showHistory) return false
      if (this.searchTerm?.length > 0) {
        this.FetchSuggestions(this.searchTerm)
      }
    }, 300),
  },
}
