
export default {
  name: 'ButtonBase',
  props: {
    onClick: { type: Function, default: () => {} },
    title: {
      type: String,
      default: 'Btn Title',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String || null,
      default: null,
    },
    size: {
      type: String,
      default: 'sm',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String || null,
      default: null,
    },
    type: {
      type: String || null,
      default: 'button',
    },
  },
}
