import * as types from './mutation-types'
import { DEFAULT_FILTERS, DEFAULT_PAGINATION, saveFiltersToLS } from './helper'
import {
  getLocalStorage,
  saveLocalStorageProp,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { INVENTORY_LS_ALIAS } from '~/components/Inventory/helper'
import { getTimestampFromString } from '~/ZenOne-StoryBook/helpers/timedate'
import { concatAndRemoveDuplicates } from '~/ZenOne-StoryBook/helpers/array'

export default {
  [types.SET_FOLDERS](state, folders) {
    state.folders = folders.sort((a, b) => {
      return (
        getTimestampFromString(a.created_at) -
        getTimestampFromString(b.created_at)
      )
    })
  },
  [types.SET_FOLDER_BY_ID](state, { id = null, data = {} }) {
    const folders = [...state.folders]
    const index = folders.findIndex(
      (folder) => parseInt(folder?.id) === parseInt(id)
    )
    if (index !== -1) {
      folders[index] = { ...data }
      state.folders = folders
    }
  },
  [types.SET_ACTIVE_FOLDER](state, folder = {}) {
    state.folder = folder
  },
  [types.SET_IS_LOADING](state, payload) {
    state.isLoading = payload
  },
  [types.SET_FOLDER_ITEMS](state, payload) {
    state.folderItems = payload
  },
  [types.SET_LAST_MOVE_ITEMS_DATA](state, payload) {
    state.lastMoveItemsData = payload
  },
  [types.SET_WORKSPACE_ITEM_REMOVED_IDS](state, payload) {
    state.workspaceItemRemovedIds = payload
  },
  [types.SET_SELECTED_LIST](state, payload) {
    state.selectedList = payload
  },
  [types.SET_MOVED_ITEMS_DESTINATION_ID](state, { folderId = null }) {
    state.dragAndDrop.table.movedItemsDestinationId = folderId
  },
  [types.SWITCH_NEED_RERENDER_NAV_TREE](state) {
    state.dragAndDrop.navTree.needRerender =
      !state.dragAndDrop.navTree.needRerender
  },
  [types.SET_IS_BLOCKED_DRAG_NAV_TREE](state, payload) {
    state.dragAndDrop.navTree.isBlocked = payload
  },
  [types.SET_FOLDER_ITEM_DATA_BY_INDEX](state, { data = {}, index = 0 }) {
    const folderItems = [...state.folderItems]
    folderItems[index] = { ...folderItems[index], ...data }
    state.folderItems = [...folderItems]
  },
  [types.SET_NEED_FETCH_ITEMS](state) {
    state.needFetchItems = !state.needFetchItems
  },
  [types.SET_SORT](state, payload) {
    state.sort = { ...payload }
    saveLocalStorageProp(INVENTORY_LS_ALIAS, 'sort', payload)
  },
  [types.RESET_PAGINATION](state) {
    state.pagination = { ...DEFAULT_PAGINATION }
  },
  [types.SET_PAGINATION](state, payload = {}) {
    state.pagination = {
      ...state.pagination,
      ...payload,
    }
  },
  [types.SET_PAGINATION_PROP](state, { propName = '', value = null }) {
    state.pagination = {
      ...state.pagination,
      [propName]: value,
    }
  },
  [types.SET_FILTER_OPTIONS](state, payload) {
    state.filterOptions = payload
  },
  [types.SET_FILTERS](state, filters = {}) {
    state.filters = filters
    const lsData = getLocalStorage(INVENTORY_LS_ALIAS)
    const lsDataCopy = {
      ...lsData,
      filters,
    }
    setLocalStorage({
      name: INVENTORY_LS_ALIAS,
      data: lsDataCopy,
    })
  },
  [types.SET_FILTER](state, { filterName = '', value = null }) {
    if (!filterName) return false
    const filters = {
      ...state.filters,
      [filterName]: value,
    }
    state.filters = filters
    saveFiltersToLS(filters)
  },
  [types.SET_CLEAR_FILTERS_TRIGGER](state) {
    state.clearFiltersTrigger = !state.clearFiltersTrigger
  },
  [types.CLEAR_FILTERS](state) {
    const filters = { ...DEFAULT_FILTERS }
    state.filters = filters
    saveFiltersToLS(filters)
  },
  [types.SET_NEED_TO_CLEAR_SEARCH](state) {
    state.needToClearSearch = !state.needToClearSearch
  },
  [types.SET_IS_NAV_TREE_RESIZING](state, payload) {
    state.isNavTreeResizing = payload
  },
  [types.SET_SHARED_FOLDER_ID_BY_ROUTE](state, payload) {
    state.sharedFolderIdByRoute = payload
  },
  [types.ADD_TO_QR_CODE_PRINT_QUEUE](state, payload) {
    const queue = concatAndRemoveDuplicates(state.qrcodePrintQueue, payload)
    state.qrcodePrintQueue = queue
    saveLocalStorageProp(INVENTORY_LS_ALIAS, 'qrcodePrintQueue', queue)
  },
  [types.CLEAR_QR_CODE_PRINT_QUEUE](state) {
    state.qrcodePrintQueue = []
    saveLocalStorageProp(INVENTORY_LS_ALIAS, 'qrcodePrintQueue', [])
  },
}
