
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SavedTotal from './SavedTotal'
import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'
export default {
  name: 'TopBar',
  components: { SavedTotal },
  computed: {
    ...mapGetters({
      workspaces: `workspace/workspaces`,
      folders: `inventory/folders`,
      rootFolderId: `inventory/rootFolderId`,
    }),
    inventoryLinkPath() {
      return `/inventory/${this.rootFolderId}`
    },
    savingsLinkPath() {
      return `/savings`
    },
    isOrderHistory() {
      return this.$route.path === '/' && this.$route.query.orderHistory
    },
    isCatalog() {
      return this.$route.path === '/' && !this.$route.query.orderHistory
    },
    isInventory() {
      return this.$route.path.includes('inventory')
    },
    isSavings() {
      return this.$route.path.includes('savings')
    },
  },
  methods: {
    ...mapActions('catalog', ['ClearFilters']),
    ...mapMutations({
      setOrderHistoryFilter: 'catalog/SET_ORDER_HISTORY',
    }),
    async toCatalog() {
      this.setOrderHistoryFilter(false)
      if (!isNotUndefined(this.$route?.query?.orderHistory)) {
        await this.$router.push('/')
      }
    },
    async toOrderHistory() {
      this.setOrderHistoryFilter(true)
      await this.$router.push('/')
    },
  },
}
