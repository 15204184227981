import actions from './actions'
import mutations from './mutations'
import {
  getFolderProp,
  getRootFolderId,
  getCurrentFolderIsRoot,
  getSingleFolderId,
  getFiltersIsActive,
  getColumnsVisibility,
  getIsSearchFilterActive,
  getIsOnlySearchFilterActive,
  getQrcodePrintQueueFromLS,
  DEFAULT_FILTERS,
  DEFAULT_PAGINATION,
  OPTIONS_BY_FILTER_NAME,
  getQrcodePrintQueueCounter,
  getCanPrintAll,
  getInitialSort,
  getVisibleColumns,
} from './helper'
import { getInventoryFilterTags } from '~/helpers/filterTags/common'
import { arrayToTree } from '~/ZenOne-StoryBook/helpers/array'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'

const inventory = {
  state: {
    sharedFolderIdByRoute: null,
    folder: {
      id: null,
      name: '',
      parent_id: null,
      customColumns: [],
      hiddenColumns: [],
    }, // currentFolder
    folders: [], // folders
    /// ////
    filterOptions: {},
    filters: DEFAULT_FILTERS,
    clearFiltersTrigger: false,
    needFetchItems: false,
    needToClearSearch: false,
    /// /////
    sort: getInitialSort(),
    /// /////
    folderItems: [],
    selectedList: [],
    lastMoveItemsData: {},
    workspaceItemRemovedIds: [], // removed from catalog/detail page by alert undo
    isLoading: false,
    pagination: DEFAULT_PAGINATION,
    isNavTreeResizing: false,
    dragAndDrop: {
      navTree: {
        dragOverItemId: null,
        needRerender: false,
        isBlocked: false,
      },
      table: {
        movedItemsDestinationId: null,
      },
    },
    qrcodePrintQueue: getQrcodePrintQueueFromLS(),
  },
  getters: {
    sort: (state) => state.sort ?? '',
    sortValue: (state, getters) => getters.sort?.value ?? '',
    /// ///////////// Inventory Folders
    needFetchItems: (state) => state.needFetchItems,
    folder: (state) => state.folder,
    folderColumnsVisibility: (state, getters) => getColumnsVisibility(getters.folder),
    folderId: (state) => state.folder?.id ?? null,
    folders: (state) => state.folders,
    foldersTree: (state) =>
      arrayToTree(
        state.folders,
        null,
        'parent_id',
        (a, b) => a?.rank - b?.rank
      ),
    hasOnlyRootFolder: (state, getters) => getters.folders?.length === 1,
    hasSingleFolder: (state, getters) => getters.folders?.length === 2,
    // todo check getter's sense
    singleFolderId: (state, getters) =>
      getSingleFolderId(getters.folders, getters.rootFolderId),
    rootFolderId: (state) => getRootFolderId(state.folders),
    currentFolderIsRoot: (state, getters) =>
      getCurrentFolderIsRoot(getters.folderId, getters.rootFolderId),
    rootFolderTotalCount: (state, getters) =>
      getFolderProp(getters.rootFolderId, getters.folders, 'count'),
    /// //////////// Inventory-items
    lastMoveItemsData: (state) => state.lastMoveItemsData,
    workspaceItemRemovedIds: (state) => state.workspaceItemRemovedIds,
    visibleColumns: (state, getters) => getVisibleColumns(getters.folder),
    folderItems: (state) => state.folderItems,
    selectedList: (state) => state.selectedList,
    movedItemsDestinationId: (state) =>
      state.dragAndDrop.table.movedItemsDestinationId,
    needRerenderNavTree: (state) => state.dragAndDrop.navTree.needRerender,
    isNavTreeDragBlocked: (state) => state.dragAndDrop.navTree.isBlocked,
    isNavTreeResizing: (state) => state.isNavTreeResizing,
    pagination: (state) => state.pagination,
    paginationFolderId: (state, getters) =>
      getters.pagination?.folderId ?? null,
    currentPage: (state, getters) => getters.pagination?.currentPage ?? 1,
    scrollPosition: (state, getters) => getters.pagination?.scrollPosition ?? 0,
    isLoading: (state) => state.isLoading,
    totalCount: (state, getters) => getters.pagination?.total ?? 0,
    /// ///////////// Inventory Filters
    filters: (state) => state.filters,
    clearFiltersTrigger: (state) => state.clearFiltersTrigger,
    filterByFolderId: (state, getters) => getters.filters?.folderId ?? [],
    filterByDistributorId: (state, getters) =>
      getters.filters?.distributorId ?? [],
    filterByRootCategoryId: (state, getters) =>
      getters.filters?.rootCategoryId ?? [],
    filterByCategoryId: (state, getters) =>
      getters.filters?.genericNameId ?? [],
    filterByManufacturerId: (state, getters) =>
      getters.filters?.manufacturerId ?? [],
    filterSearch: (state, getters) => getters.filters?.search ?? '',
    isFiltersActive: (state, getters) => getFiltersIsActive(getters.filters),
    isSearchFilterActive: (state, getters) =>
      getIsSearchFilterActive(getters.filterSearch),
    isOnlySearchFilterActive: (state, getters) =>
      getIsOnlySearchFilterActive(
        getters.filters,
        getters.isSearchFilterActive
      ),
    filterOptions: (state) => state.filterOptions,
    filterOptionsIsReady: (state, getters) =>
      isHavingProps(getters.filterOptions),
    filterTags: (state, getters) =>
      getInventoryFilterTags(
        getters.filterOptions,
        getters.filters,
        DEFAULT_FILTERS,
        OPTIONS_BY_FILTER_NAME
      ),
    needToClearSearch: (state) => state.needToClearSearch,
    sharedFolderIdByRoute: (state) => state.sharedFolderIdByRoute,
    qrcodePrintQueue: (state) => state.qrcodePrintQueue ?? [],
    qrcodePrintQueueCounter: (state, getters) =>
      getQrcodePrintQueueCounter(getters.qrcodePrintQueue),
    canPrintAll: (state, getters) => getCanPrintAll(getters.folders),
  },
  mutations,
  actions,
  namespaced: true,
}

export default inventory
